export const IMAGE_TYPENAME = 'Image'
export const VIDEO_TYPENAME = 'Video'
export const RECIPE_TYPENAME = 'Recipe'
export const PERSONALIZED_LIST_URI =
  'nyt://per/personalized-list/cooking-carousel'

export const CROPS = {
  LARGE_HORIZONTAL_375: 'largeHorizontal375',
  MEDIUM_THREE_BY_TWO_210: 'mediumThreeByTwo210',
  MEDIUM_THREE_BY_TWO_225: 'mediumThreeByTwo225',
  MEDIUM_THREE_BY_TWO_252: 'mediumThreeByTwo252',
  MEDIUM_THREE_BY_TWO_378: 'mediumThreeByTwo378',
  MEDIUM_THREE_BY_TWO_440: 'mediumThreeByTwo440',
  THREE_BY_TWO_MEDIUM_AT_2X: 'threeByTwoMediumAt2X',
  VIDEO_SIXTEEN_BY_NINE_310: 'videoSixteenByNine310',
  RECIRCULATION_CROP_151: 'blogSmallInline',
  ARTICLE_LARGE: 'articleLarge',
  T_MAG_SF: 'tmagSF',
  BLOG_480: 'blog480',
  MASTER_768: 'master768',
  IPAD_JUMBO_NONRETINA: 'ipad_jumbo_nonretina',
  SQUARE_640: 'square640',
  VERTICAL_TWO_BY_THREE_735: 'verticalTwoByThree735',
  JUMBO: 'jumbo',
}

export const SIMILAR_RECIPE_CROP_NAMES = [
  CROPS.VIDEO_SIXTEEN_BY_NINE_310,
  CROPS.MEDIUM_THREE_BY_TWO_210,
  CROPS.MEDIUM_THREE_BY_TWO_440,
  CROPS.MEDIUM_THREE_BY_TWO_252,
  CROPS.MEDIUM_THREE_BY_TWO_378,
  CROPS.LARGE_HORIZONTAL_375,
  CROPS.RECIRCULATION_CROP_151,
]

export const RECIPE_CARD_CROP_NAMES = [
  CROPS.MEDIUM_THREE_BY_TWO_252,
  CROPS.ARTICLE_LARGE,
  CROPS.VERTICAL_TWO_BY_THREE_735,
  CROPS.MEDIUM_THREE_BY_TWO_440,
]

export const EDITOR_COLLECTION_CROP_NAMES = [
  CROPS.ARTICLE_LARGE,
  CROPS.MASTER_768,
  CROPS.BLOG_480,
  CROPS.T_MAG_SF,
]
