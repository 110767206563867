// Active Tests
export const PHOEBE_TEST = 'COOK_TP50_PhoebeTest'
export const SHOW_APP_DOWNLOAD_BANNER_TO_REGIS = 'COOK_WEB_TP35_mWebUpsellRegi'
export const SAVE_MOMENT_TEST = 'COOK_WEB_TP36_WebSaveMoment'

// Default Control Variant
export const CONTROL = '0_Control'

export const SAVE_MOMENT_ANALYZE = '1_newsaveanalyze'
export const SAVE_MOMENT_NO_ANALYZE = '2_newsavedontanalyze'
