import transformJson from 'utils/transformJson'
import {
  IMAGE_TO_SAMIZDAT_CROP_MAP,
  DEFAULT_VIEWPORT_CROPS,
} from 'api/services/cookingAPI/schemas/image/constants'
import { $TSFixMe } from 'types'

function getCropForName(crops: $TSFixMe, imageName: $TSFixMe) {
  const cropMap: $TSFixMe = IMAGE_TO_SAMIZDAT_CROP_MAP
  const desired: $TSFixMe = cropMap[imageName]
  return crops.find((crop: $TSFixMe) => crop.name === desired)
}

const getImageBySizes = (crops: $TSFixMe, sizes: $TSFixMe) => {
  return {
    XL: crops.find(({ name }: $TSFixMe) => name === sizes.XL)?.url ?? null, // desktop with big screen
    L: crops.find(({ name }: $TSFixMe) => name === sizes.L)?.url ?? null, // desktop
    M: crops.find(({ name }: $TSFixMe) => name === sizes.M)?.url ?? null, // tablet
    S: crops.find(({ name }: $TSFixMe) => name === sizes.S)?.url ?? null, // Mobile
    XS: crops.find(({ name }: $TSFixMe) => name === sizes.XS)?.url ?? null, // smaller device
  }
}

const getImageCrops = (crops: $TSFixMe) => {
  return Object.entries(DEFAULT_VIEWPORT_CROPS).reduce((acc, [key, sizes]) => {
    return {
      ...acc,
      [key]: getImageBySizes(crops, sizes),
    }
  }, {})
}

export function getImageSources(crops: $TSFixMe) {
  if (!crops) return null

  // Build the sources object by mapping legacy crop to image name
  const reducerFn = (sources: $TSFixMe, imageName: $TSFixMe) => {
    const found = getCropForName(crops, imageName)

    return {
      ...sources,
      [imageName]: found?.url ?? '', // default to empty string if we can't find crop
    }
  }
  return Object.keys(IMAGE_TO_SAMIZDAT_CROP_MAP).reduce(reducerFn, {})
}

const imageSchema = {
  caption: 'image.caption',
  credit: 'image.credit',
  src: (recipe: $TSFixMe) => getImageSources(recipe.image?.crops),
  crops: (recipe: $TSFixMe) => getImageCrops(recipe.image?.crops || []),
}

const folderImageSchema = {
  id: 'id',
  caption: 'caption',
  credit: 'credit',
  crops: (image: $TSFixMe) => getImageCrops(image?.crops || []),
}

export const folderImagesSchema = (image: $TSFixMe) => {
  return image ? transformJson(image, folderImageSchema) : null
}

const transformedImage = (recipe: $TSFixMe) =>
  recipe.image ? transformJson(recipe, imageSchema) : null

export default transformedImage
