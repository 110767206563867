export const FIRST_SAVE = 'firstSave'
export const FIRST_RECIPE_BOX_FILTER_POPOVER = 'firstRecipeBoxFilterPopover'
export const FIRST_INSTACART_INTEGRATION_CLICK =
  'firstInstacartIntegrationClick'
export const SECOND_INSTACART_INTEGRATION_CLICK =
  'secondInstacartIntegrationClick'
export const THIRD_INSTACART_INTEGRATION_CLICK =
  'thirdInstacartIntegrationClick'

export const INSTACART_MODAL = 'instacartModal'
