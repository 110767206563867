import { CROPS } from 'api/services/samizdat/constants'

// All supported image names and their associated Samizdat crops
export const IMAGE_TO_SAMIZDAT_CROP_MAP = {
  card: CROPS.MEDIUM_THREE_BY_TWO_440,
  article: CROPS.ARTICLE_LARGE,
}

export const DEFAULT_VIEWPORT_CROPS = {
  collection: {
    XS: CROPS.IPAD_JUMBO_NONRETINA,
    S: CROPS.IPAD_JUMBO_NONRETINA,
    M: CROPS.IPAD_JUMBO_NONRETINA,
    L: CROPS.IPAD_JUMBO_NONRETINA,
    XL: CROPS.IPAD_JUMBO_NONRETINA,
  },
  recipe: {
    XS: CROPS.T_MAG_SF,
    S: CROPS.BLOG_480,
    M: CROPS.MASTER_768,
    L: CROPS.JUMBO,
    XL: CROPS.THREE_BY_TWO_MEDIUM_AT_2X,
  },
  card: {
    XS: CROPS.MEDIUM_THREE_BY_TWO_440,
  },
  rotd: {
    XS: CROPS.MEDIUM_THREE_BY_TWO_440,
    S: CROPS.MEDIUM_THREE_BY_TWO_440,
    M: CROPS.MEDIUM_THREE_BY_TWO_440,
    L: CROPS.MEDIUM_THREE_BY_TWO_440,
    XL: CROPS.THREE_BY_TWO_MEDIUM_AT_2X,
  },
}
