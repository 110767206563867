import { useMemo } from 'react'
import { useGetDeviceInfo } from 'utils/device'
import { useUser } from 'hooks/useUser'
import useModalContext from 'contexts/modal/ModalContext'
import { pageTypes, useCurrentPage } from 'utils/pages'
import { AppDownloadCookie } from 'utils/cookies'
import {
  APP_DOWNLOAD_BANNER_SUB_ACCEPT,
  APP_DOWNLOAD_BANNER_SUB_DECLINE,
} from '../constants'
import {
  APP_UPSELL_MODAL,
  INSTACART_MODAL,
  NEWSLETTER_MODAL,
} from 'components/shared/Modals/helpers/constants'

// Only show the App Download Banner if the user is on the RDP, a subscriber,
// hasn't interacted with the App Download Banner before, has native app support,
// and is not on a tablet device.
export const useShouldShowAppDownloadBanner = () => {
  const page = useCurrentPage()
  const { user } = useUser()
  const { modalState, isDoneFetching } = useModalContext()
  const { nativeAppName, isTablet } = useGetDeviceInfo()
  const hasInteractedWithBannerBefore = [
    APP_DOWNLOAD_BANNER_SUB_ACCEPT,
    APP_DOWNLOAD_BANNER_SUB_DECLINE,
  ].includes(AppDownloadCookie)

  const handleModals = (): boolean => {
    if (isDoneFetching && modalState?.id) {
      return ![NEWSLETTER_MODAL, INSTACART_MODAL, APP_UPSELL_MODAL].includes(
        modalState?.id,
      )
    } else return isDoneFetching
  }

  return useMemo(() => {
    if (!user?.isSubscribed || hasInteractedWithBannerBefore) {
      return false
    }

    const isModalNotDisplayed = handleModals()

    return (
      !!nativeAppName &&
      !isTablet &&
      page === pageTypes.RECIPE &&
      isModalNotDisplayed
    )
  }, [
    page,
    nativeAppName,
    hasInteractedWithBannerBefore,
    isTablet,
    user?.isSubscribed,
    isDoneFetching,
  ])
}
