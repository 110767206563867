import { useCallback, useMemo } from 'react'
import { pageTypes, useCurrentPage } from 'utils/pages'
import { ModalStateProps } from 'contexts/modal/ModalContext'
import {
  REGIWALL,
  STATIC_PAYWALL,
  PAYWALL,
  NEWSLETTER_MODAL,
  APP_UPSELL_MODAL,
} from 'components/shared/Modals/helpers/constants'
import { UserInfo } from 'contexts/user/types'
import { useAppContext } from 'contexts/app/AppContext'
import { SHOW_GROCERY_SERVICE_INTEGRATION } from 'contexts/app/featureFlags'
import { NYTGeoCookie } from 'utils/cookies'
import { GEO_TARGETED_COUNTRIES } from 'components/recipe/Ingredients/constants'

// Show the Instacart Modal if:
// - the user is on the RDP.
// - user is in the 1_showIntegration variant for experiment COOK_WEB_TP38_GroceryListIntegration.
// - hasn't interacted with the Lightbox Modal before.
// - has completed onboarding
// - is a subscriber
// - on new subs 2nd visit on a RDP and on an existing subs 1st visit to a RDP.
// - is not showing the paywall, regi-wall, static paywall, newsletters, app upsell modal.
// - the Instacart Integration feature flag is turned on.
// - The user is in US or Canada
export const useShouldShowInstacartModal = (
  user: UserInfo,
  modalState: Partial<ModalStateProps>,
  isFromInstacart: boolean,
) => {
  const page = useCurrentPage()
  const { getFeatureFlag } = useAppContext()

  const handleUserOnboardedAndSubscribed = useCallback(() => {
    if (!user?.userActions) return false
    const isUserOnboarded: boolean =
      user.userActions?.newsletterModal && user.userActions.appUpsellModal

    return isUserOnboarded && !!user?.isSubscribed
  }, [user])

  return useMemo((): boolean => {
    const isUserInGeoTargetCountry =
      GEO_TARGETED_COUNTRIES.includes(NYTGeoCookie)
    const hasUserCompletedOnboardingAndSubscribed: boolean =
      handleUserOnboardedAndSubscribed()
    const isOnRDP = page === pageTypes.RECIPE
    const isInstacartIntegrationEnabled = getFeatureFlag(
      SHOW_GROCERY_SERVICE_INTEGRATION,
    )
    const hasNotInteractedWithModal = !user?.userActions?.instacartModal
    const isModalNotDisplayed = modalState?.id
      ? ![
          PAYWALL,
          REGIWALL,
          NEWSLETTER_MODAL,
          STATIC_PAYWALL,
          APP_UPSELL_MODAL,
        ].includes(modalState?.id)
      : true

    return (
      !isFromInstacart &&
      isUserInGeoTargetCountry &&
      hasUserCompletedOnboardingAndSubscribed &&
      isOnRDP &&
      hasNotInteractedWithModal &&
      isModalNotDisplayed &&
      isInstacartIntegrationEnabled
    )
  }, [page, modalState, user, getFeatureFlag, isFromInstacart])
}
