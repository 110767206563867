import { useMemo } from 'react'
import { useGetDeviceInfo } from 'utils/device'
import { useUser } from 'hooks/useUser'
import { pageTypes, useCurrentPage } from 'utils/pages'
import { AppDownloadCookie } from 'utils/cookies'
import useModalContext from 'contexts/modal/ModalContext'
import {
  REGIWALL,
  STATIC_PAYWALL,
  PAYWALL,
  NEWSLETTER_MODAL,
  INSTACART_MODAL,
} from 'components/shared/Modals/helpers/constants'
import {
  APP_DOWNLOAD_BANNER_REGI_ACCEPT,
  APP_DOWNLOAD_BANNER_REGI_DECLINE,
} from 'components/shared/AlertBanners/constants'

// Only show the App Download Banner if:
// - the user is on the RDP
// - the user is a regi,
// - hasn't interacted with the App Download Banner before as a regi
// - is not showing the paywall, regi-wall, static paywall or newsletters modals
// - is device compatible: not on a tablet && is iOS
// TODO: after the CENG-2631 experiment is completed this file can be consolidated with ./useShouldShowAppDownloadBanner.js and present the banner
export const useShouldShowRegiAppDownloadBanner = () => {
  const page = useCurrentPage()
  const cookie = AppDownloadCookie
  const { user } = useUser()
  const { isTablet, isIOS } = useGetDeviceInfo()
  const { modalState } = useModalContext()

  return useMemo(() => {
    const isOnRDP = page === pageTypes.RECIPE
    const isRegi = user?.isRegistered
    const hasNotInteractedWithBanner = ![
      APP_DOWNLOAD_BANNER_REGI_ACCEPT,
      APP_DOWNLOAD_BANNER_REGI_DECLINE,
    ].includes(cookie)
    const isModalNotDisplayed =
      modalState?.id &&
      ![
        PAYWALL,
        REGIWALL,
        NEWSLETTER_MODAL,
        STATIC_PAYWALL,
        INSTACART_MODAL,
      ].includes(modalState?.id)
    const isDeviceCompatible = isIOS && !isTablet
    return (
      isOnRDP &&
      isRegi &&
      hasNotInteractedWithBanner &&
      isModalNotDisplayed &&
      isDeviceCompatible
    )
  }, [page, cookie, user, isTablet, isIOS, modalState])
}
