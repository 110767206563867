import {
  useEffect,
  useRef,
  useState,
  useCallback,
  PropsWithChildren,
  useMemo,
} from 'react'
import cookingAPI from 'api/services/cookingAPI'
import { useMessageSelection } from '@nyt/onsite-messaging-components/dist/Hooks/useMessageSelection'
import { useUnit } from '@nyt/onsite-messaging-components/dist/Hooks/useUnit'
import { config as regiwallConfig } from '@nyt/onsite-messaging-components/dist/Units/CookingRegiwall/config'
import { config as paywallConfig } from '@nyt/onsite-messaging-components/dist/Units/CookingPaywall/config'
import { getModalDetails } from 'components/shared/Modals/helpers/modalDetails'
import { validateModalType } from 'components/shared/Modals/helpers/modalErrorHandler'
import useOverlay from 'hooks/useOverlay'
import { useEventTracker } from 'contexts/event_tracking'
import {
  ModalActionContext,
  ModalContext,
  ModalStateProps,
} from './ModalContext'
import { recordError } from 'utils/errors'
import {
  INSTACART_MODAL,
  PAYWALL,
  REGIWALL,
} from 'components/shared/Modals/helpers/constants'
import { $TSFixMe } from 'types'
import { useUser } from 'hooks/useUser'
import { useShouldShowInstacartModal } from 'components/shared/Modals/InstacartModal/useShouldShowInstacartModal'

/**
 * AccessResponse is the expected shape of the data returned from the
 * /access endpoint
 */
interface AccessResponse {
  modalType: Nullable<string>
}

const initialModalState = {}

/**
 * ModalProvider - Context provider responsible for state management and rendering of modals.
 */
const ModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isDoneFetching, setIsDoneFetching] = useState<boolean>(false)
  const [shouldFireOMAImpression, setShouldFireOMAImpression] = useState(true)
  const selectionPaywallData = useMessageSelection({ config: paywallConfig })
  const selectionRegiwallData = useMessageSelection({ config: regiwallConfig })
  const { unit: paywallUnit } = useUnit(paywallConfig)
  const { unit: regiwallUnit } = useUnit(regiwallConfig)
  const [accessResponse, setAccessResponse] = useState<
    AccessResponse | undefined
  >(undefined)
  const [modalState, setModalState] =
    useState<Partial<ModalStateProps>>(initialModalState)
  const isModalStateEmpty = () => modalState === null

  const resetModalState = useCallback(
    () => setModalState(initialModalState),
    [],
  )

  const { user, isLoading: isUserDataLoading } = useUser()

  // hook used to control the open/close status of modals.
  const { isOpen, onOpen, ref, onClose } = useOverlay({
    isCloseable: !isModalStateEmpty() ? modalState.isCloseable : true,
    closeOnEsc: !isModalStateEmpty() ? modalState.isCloseable : true,
    isOpenOnMount: !isModalStateEmpty(),
    onCloseCallback: modalState.onCloseCallback,
  })

  const eventTracker = useEventTracker()
  const hasFetchedModal = useRef(false)
  const hasSetModal = useRef(false)

  const isGiftArticle =
    selectionPaywallData?.response?.meter()?.data?.grantReason ===
    'UNLOCKED_ARTICLE_CODE'
  const isFromInstacart =
    selectionPaywallData?.response?.meter()?.data?.assetType ===
    'instacart-licensed'
  const showPaywall: boolean =
    selectionPaywallData?.response?.meter()?.data?.gatewayType === 'PAYWALL' &&
    !selectionPaywallData?.response?.meter()?.data?.granted
  const showRegiwall: boolean =
    selectionRegiwallData?.response?.meter()?.data?.gatewayType ===
      'REGIWALL' &&
    !selectionRegiwallData?.response?.meter()?.data?.granted &&
    !isGiftArticle

  const isShownInstacartModal = useShouldShowInstacartModal(
    user,
    modalState,
    isFromInstacart,
  )

  const configureModalDetails = useCallback(
    (modalType?: string, modalFields: Partial<ModalStateProps> = {}) => {
      if (!modalType) {
        resetModalState()
        onClose()
        return
      }

      const modalConfig = getModalDetails(modalType)
      const modal = { ...modalConfig, ...modalFields }

      setModalState(modal)
      onOpen()
    },
    [onClose, onOpen, resetModalState],
  )

  useEffect(() => {
    configureModalDetails(modalState?.id, {
      isFullScreen: true,
    })
  }, [])

  useEffect(() => {
    let isMounted = true
    const isEventTrackerLoading =
      eventTracker !== null && eventTracker.isLoading

    if (
      !hasFetchedModal.current &&
      !isEventTrackerLoading &&
      !isUserDataLoading
    ) {
      const fetchData = async () => {
        const url = window.location.href
        const modal = await cookingAPI.page.fetchModalType(url, {
          pageviewId: window.nyt_et?.get_pageview_id?.(),
          userType: user?.userType,
        })
        hasFetchedModal.current = true
        return modal
      }

      fetchData()
        .then((modalResponse) => {
          if (isMounted && modalResponse.data) {
            setAccessResponse(modalResponse.data)
          }
        })
        .catch(recordError)
    }

    return () => {
      isMounted = false
    }
  }, [eventTracker, isUserDataLoading])

  /**
   * This hook handles configuring the modal details
   * The modal is ready once:
   * - The /access API endpoint response is returned
   * - The Message Selection response is returned
   */
  useEffect(() => {
    if (hasSetModal.current) {
      return
    }

    // enable overriding the Abra parameters via query parameters for testing
    // note: this is a capability that QA expects on the Onsite Messaging side
    const searchParams = new URLSearchParams(window?.location?.search)
    // debug query param pass to view Message Selection log
    const debug = Boolean(searchParams.get('debug'))

    // `undefined` is a valid modal type in the cooking system, equating to no modal
    let modalType

    // if the /access API endpoint returned a modal, it should be set
    // unless it's a gift article
    // (this is because the API returns null for PAYWALL checks now)
    // source: https://github.com/nytimes/np-cooking/blob/develop/api/app/services/meter_access_service.rb#L24
    if (accessResponse?.modalType && !isGiftArticle) {
      // Since OMA is now integrated, we should always ignore access' response if it is ever PAYWALL or REGIWALL
      if (
        accessResponse?.modalType !== PAYWALL &&
        accessResponse?.modalType !== REGIWALL
      ) {
        modalType = accessResponse?.modalType
      }
    }

    // We run through all the other checks before we configure the lightbox modal.
    // If modalType === anything but undefined, we don't want to show the lightbox.
    if (isShownInstacartModal && modalType === undefined) {
      modalType = INSTACART_MODAL
    }

    // If Message Selection is NOT available at this point, return early
    if (
      !selectionPaywallData?.response?.meter?.()?.data ||
      !selectionRegiwallData?.response?.meter?.()?.data
    ) {
      return
    }

    // set the modalType to PAYWALL if Message Selection says to serve paywall
    if (showPaywall && paywallUnit?.canShow) {
      if (debug) console.log(`Message Selection says to ${PAYWALL}`)
      modalType = PAYWALL
    }

    // Note: The constant REGIWALL modalType actually equates to 'REGIMODAL' here
    if (showRegiwall && regiwallUnit?.canShow) {
      if (debug) console.log(`Message Selection says to ${REGIWALL}`)
      modalType = REGIWALL
    }

    configureModalDetails(modalType)
    hasSetModal.current = true
    setIsDoneFetching(true)
  }, [
    accessResponse,
    configureModalDetails,
    selectionPaywallData?.response?.meter?.()?.data,
    selectionRegiwallData?.response?.meter?.()?.data,
    showPaywall,
    showRegiwall,
    regiwallUnit?.canShow,
    paywallUnit?.canShow,
  ])

  /**
   * @param type - The custom modal type (e.g. PayWallModal).
   * @param isCloseable - Determines whether the user is behind a "soft" or "hard" paywall.
   */
  const replaceModal = useCallback(
    ({ type, isCloseable = true }: { type: string; isCloseable: boolean }) => {
      validateModalType(type)
      const modalFields = { id: type, isCloseable }
      configureModalDetails(type, modalFields)
    },
    [configureModalDetails],
  )

  /**
   * @param type - The modal type of the modal to currently show. (e.g. PayWallModal).
   * @param pastModalType - The modal type of the previous modal.
   * @param modalFields - Optional modal props that can be added to custom modal state.
   * Adds a "past" modal state object property which holds the previous modal state.
   */
  const goToNextModal = useCallback(
    ({
      type,
      pastModalType,
      modalFields = {},
    }: {
      type: string
      pastModalType: string
      modalFields: Partial<ModalStateProps>
    }) => {
      validateModalType(type)
      const pastModalState = {
        past: getModalDetails(pastModalType),
      }
      configureModalDetails(type, { ...modalFields, ...pastModalState })
    },
    [configureModalDetails],
  )

  /**
   * References and navigates to the "past" modal state.
   */
  const goToPastModal = () => {
    configureModalDetails(modalState?.past?.id)
  }

  /**
   * @param type - The custom modal type (e.g. PayWallModal).
   * @param modalFields - Optional modal props that can be added to custom modal state.
   * Used in instances where we want to pass in specific props (e.g. eventLabel) with the custom modal.
   */
  const showModal = useCallback(
    ({
      type,
      modalFields = {},
    }: {
      type: string
      modalFields?: Partial<ModalStateProps>
    }) => {
      validateModalType(type)
      onOpen()
      configureModalDetails(type, modalFields)
    },
    [onOpen, configureModalDetails],
  )

  /**
   * Used in instances where we have a modal type to render but we want to remove/hide it. (e.g. Abra)
   */
  const suppressModal = useCallback(() => {
    configureModalDetails()
    return null
  }, [configureModalDetails])

  const handleForceHideModal = (shouldForceHideModal: $TSFixMe) => {
    if (shouldForceHideModal) {
      suppressModal()
    } else {
      configureModalDetails(modalState?.id, { shouldForceHideModal: false })
    }
  }

  /**
   * Modal actions that are passed down to the ModalActionContext. These
   * _cannot_ have dependencies on the modalState, or the site will suffer from
   * excessive rerenders as all components that use the ModalActionContext will
   * rerender on every modal state change. See:
   * - CA-2623: https://jira.nyt.net/browse/CA-2623
   *
   * This is why handleForceHideModal and goToPastModal are not included
   * in the below array.
   */
  const modalActions = useMemo(
    () => ({
      onOpen,
      onClose,
      showModal,
      replaceModal,
      goToNextModal,
      suppressModal,
      setModalState,
      resetModalState,
    }),
    [
      onOpen,
      onClose,
      showModal,
      replaceModal,
      goToNextModal,
      suppressModal,
      resetModalState,
    ],
  )

  return (
    <ModalContext.Provider
      value={{
        modalState,
        isDoneFetching,
        isOpen,
        containerRef: ref,
        shouldFireOMAImpression,
        setShouldFireOMAImpression,
        handleForceHideModal,
        goToPastModal,
      }}
    >
      <ModalActionContext.Provider value={modalActions}>
        {children}
      </ModalActionContext.Provider>
    </ModalContext.Provider>
  )
}

export default ModalProvider
